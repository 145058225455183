import "./createSegment.css";
import { useRef, useState, useEffect } from "react";
import SingleSegment from "./SingleSegment";
import MultipleSegment from "./MultipleSegment";

export default function CreateSegment({ setCreateModal }) {
  const modalRef = useRef(null);
  const [step, setStep] = useState("nameSegment");
  const [singleStep, setSingleStep] = useState("segmentCategory");
  const [multipleStep, setMultipleStep] = useState("segmentCategory");
  const [inputs, setInputs] = useState({
    name: "",
    segmentType: "",
    userRole: "",
    segmentCategory: "",
    targetMarket: "",
    characteristic: "",
    leadQuality: "",
    contacts: "",
    multipleData: [],
  });
  const [selectedId, setSelectedId] = useState(null);

  function closeModal() {
    setCreateModal(false);
  }
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setCreateModal(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setCreateModal]);

  const h2 = getHeaderText(step, inputs, singleStep);

  function createMultipleSegment() {
    if (inputs.segmentType !== "multiple") return;

    setInputs((prev) => {
      const id = crypto.randomUUID();
      setSelectedId(id);
      return {
        ...prev,
        multipleData: [
          ...prev.multipleData,
          {
            id,
            userRole: "",
            segmentCategory: "",
            targetMarket: "",
            characteristic: "",
            leadQuality: "",
          },
        ],
      };
    });
  }

  return (
    <div className="create-container">
      <div ref={modalRef} className="modal">
        <header>
          <h2>{h2}</h2>
          <span>{inputs?.segmentType}</span>
        </header>
        <div className="modal-body">
          {renderModalContent(
            step,
            inputs,
            setInputs,
            setStep,
            singleStep,
            setSingleStep,
            multipleStep,
            setMultipleStep,
            selectedId,
            createMultipleSegment,
            closeModal
          )}
        </div>
        <button className="cancel" onClick={closeModal}>
          Cancel
        </button>
      </div>
    </div>
  );
}

function getHeaderText(step, inputs, singleStep) {
  if (step === "nameSegment") return "Name A Segment";
  if (step === "segmentType") return "Choose a Segment Type";
  if (step === "segmentCategory") {
    if (inputs.segmentType === "multiple")
      return "Create a Multiple Audience Segment";
    if (
      singleStep === "targetMarket" &&
      inputs.segmentCategory === "lead quality"
    )
      return "Choose Lead Quality";
    if (singleStep === "targetMarket") return "Choose a Target Market";
    return "Choose Segment Category";
  }
  return "Create A Segment";
}

function renderModalContent(
  step,
  inputs,
  setInputs,
  setStep,
  singleStep,
  setSingleStep,
  multipleStep,
  setMultipleStep,
  selectedId,
  createMultipleSegment,
  closeModal
) {
  switch (step) {
    case "nameSegment":
      return (
        <>
          <input
            type="text"
            placeholder="Enter Segment Name"
            value={inputs.name}
            onChange={(e) =>
              setInputs((prev) => ({ ...prev, name: e.target.value }))
            }
          />
          <div className="modal-actions">
            <button
              disabled={inputs.name.length < 4}
              onClick={() => setStep("segmentType")}
            >
              Next
            </button>
          </div>
        </>
      );
    case "segmentType":
      return (
        <>
          <div className="segment-type">
            <label
              onClick={() =>
                setInputs((prev) => ({
                  ...prev,
                  segmentType: prev.segmentType === "single" ? "" : "single",
                }))
              }
              className={inputs.segmentType === "single" ? "active" : ""}
            >
              Single
            </label>
            <label
              onClick={() =>
                setInputs((prev) => ({
                  ...prev,
                  segmentType:
                    prev.segmentType === "multiple" ? "" : "multiple",
                }))
              }
              className={inputs.segmentType === "multiple" ? "active" : ""}
            >
              Multiple
            </label>
          </div>
          <div className="modal-actions">
            <button
              onClick={() => {
                setInputs((prev) => ({ ...prev, segmentType: "" }));
                setStep("nameSegment");
              }}
            >
              Back
            </button>
            <button
              onClick={() => {
                setStep("segmentCategory");
                createMultipleSegment();
              }}
              disabled={!inputs.segmentType}
            >
              Next
            </button>
          </div>
        </>
      );
    case "segmentCategory":
      if (inputs.segmentType === "single") {
        return (
          <SingleSegment
            singleStep={singleStep}
            setSingleStep={setSingleStep}
            setInputs={setInputs}
            inputs={inputs}
            setStep={setStep}
            closeModal={closeModal}
          />
        );
      } else if (inputs.segmentType === "multiple") {
        return (
          <MultipleSegment
            setMultipleStep={setMultipleStep}
            multipleStep={multipleStep}
            setInputs={setInputs}
            inputs={inputs}
            setStep={setStep}
            selectedId={selectedId}
            setSelectedId={selectedId}
            createMultipleSegment={createMultipleSegment}
          />
        );
      }
      return null;
    default:
      return null;
  }
}
