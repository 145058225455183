import React, { useEffect, useState, useRef } from "react";
// import EmailLogo from '../../assets/EmailLogo.svg'
import BigTriangle from "../../assets/BigTriangle.svg";
import SmallTriangle from "../../assets/SmallTriangle.svg";
import "./CreateEmail.css";
import EmailPreview from "./EmailPreview";
import {Link, useOutletContext} from "react-router-dom"

import ReactQuill, { Quill } from 'react-quill';
import "react-quill/dist/quill.snow.css"; // Import Quill styles

import { UploadButton } from '@bytescale/upload-widget-react'


import emailFeature from "../../https/email";

// Step 1: Define custom fonts
const fonts = ['Arial', 'Courier', 'Times-New-Roman', 'Comic-Sans', 'Georgia'];
const Font = Quill.import('formats/font');
Font.whitelist = fonts; // Step 2: Register the fonts
Quill.register(Font, true);

// Step 3: Define custom toolbar with font options
const modules = {
  toolbar: [
    [{ font: fonts }], // Font selection
    [{ header: [1, 2, 3, false] }], // Header formatting
    ['bold', 'italic', 'underline'], // Text formatting
    [{ list: 'ordered' }, { list: 'bullet' }], // Lists
    ['link', 'image'], // Link and image options
    ['clean'], // Clear formatting
  ],
};

const formats = [
  'font', 'header', 'bold', 'italic', 'underline', 'list', 'bullet',
  'color', 'background', 'link', 'image'
];




const options = {
  apiKey: "free", // Get API key: https://www.bytescale.com/get-started
  maxFileCount: 1,
};



function EditEmail({data,updateList,notify,closeModel}) {
    console.log(data);
    
    
  
    const [emailKeywords, setEmailKeywords] = useState(data.keyword);
    const [emailTitle, setEmailTitle] = useState(data.title);
    const [emailDescription, setEmailDescription] = useState(data.description);
    const [header, setHeader] = useState(data.header);
    const [org, setOrg] = useState(data.organisation);
    const [website, setWebsite] = useState(data.social[0].Website);
    const [email, setEmail] = useState(data.social[0].email);
    const [phone, setPhone] = useState(data.social[0].phone);
    const [tags, setTags] = useState('');
    const [content, setContent] = useState(data.content);
    const [logo, setLogo] = useState(null);

    

    const addTags = (newTag) => {
        setTags((prevTags) => [...prevTags, newTag]);
      };
    
      const removeTag = (tagToRemove) => {
        setTags((prevTags) => prevTags.filter((tag) => tag !== tagToRemove));
      };


    //   https://ihubbackend2.xcelsz.com/api/email/edit-email/1





      const [displayField, setDisplayField] = useState("")
      const [pdfFile, setPDFFile] = useState("")
      const [mediaDisplay, setMediaDisplay] = useState()
      const [mediaUrl, setMediaUrl] = useState("")
      const [emailImage, setEmailImage] = useState(data.image);
      const [banner, setBanner] = useState(data.footerbanner);
      const [signature, setSignature] = useState(data.signature);
      const [author, setAuthor] = useState(data.author);
      const [position, setPosition] = useState(data.position);
      
  
     // function to upload images
     const FileUpload = ({name}) => (
      <UploadButton options={options}
                    onComplete={(files) =>{
                      const url = files.map((x) => x.fileUrl).join("\n")
                      if (url !=='' && mediaDisplay === "logo") {
                          setLogo(url)
                      }
                      if (url !=='' && mediaDisplay === "banner") {
                          setBanner(url)
                          console.log("it a banner")
                      }
                      if (url !=='' && mediaDisplay === "image") {
                          setEmailImage(url)
                          console.log("it an image")
                      }
  
                  }}>
        {({onClick}) =>
          <button onClick={onClick}>
            Upload a {name}...
          </button>
        }
      </UploadButton>
    )
  
    const fileInputRef = useRef(null)
    
    const handleUploadPdf = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click()
      }
    }
    console.log(pdfFile.name)





    const saveEdit = async ()=>{
        const pushData={
          "title": emailTitle,
          "header": header,
          "description": emailDescription,
          "content":content,
          "signature": signature,
          "author": author,
          "position": position,
          "organisation":org,
          "social":[
            {
              "Website":website,
              "email":email,
              "phone":phone
            }
          ],
        //   "keyword":emailKeywords.join(",")
      }
      console.log(pushData);
      
        try {
          const res = await emailFeature.editEmail(data.id,pushData);
          console.log(res);
          updateList()
          closeModel()
          notify('Edit Saved Sucessfully')

        } catch (error) {
          console.log(error);
          notify('Edit Failed')
        }
      }

  

  return (
        <div style={{ position:'fixed', background:'#00000052', height:'100vh', top:0, left:0, right:0, zIndex:990, display:"flex", alignItems:'center',justifyContent:'center' }}>
         {/* <div style={{ display:'flex' }}> */}
            <form className="email-form-section" style={{ display:'flex',maxWidth:'800px', background:'white', height:'90%', overflow:'scroll', position:'relative' }}>
                <div>
                <h3 style={{ padding:'1rem' }}>Edit Email</h3>
                <p onClick={closeModel} style={{ position:'absolute', right:10, top:10, cursor:'pointer' }}>X</p>
                <div className="email-form-input">
            <label htmlFor="email-titile">Header</label>
            <ReactQuill
                id="email-description"
                name="emailDescription"
                value={header}
                onChange={setHeader}
                modules={modules}
                formats={formats}
                style={{ width: '450px' }}
                placeholder="Write your email description here..."
            />
            {/* <input
                style={{height:'30px'}}
                type="text"
                id="email-title"
                name="emailTitle"
                onChange={(e) => setHeader(e.target.value)}
            /> */}
                </div>
                <div>
                <div className="email-form-input">
                    <label htmlFor="email-titile">Socails</label>
                </div>
                <div  style={{ display:'flex', flexWrap:'wrap', width:'450px', justifyContent:'space-between'}}>
            {/* <div className="" style={{display:'flex', flexDirection:'column', justifyContent:"space-between", marginBottom:'.4rem'}}>
                <label htmlFor="email-titile">Phone</label>
                <input
                    style={{height:'30px', padding:"0 1rem"}}
                    type="text"
                    id="email-title"
                    name="emailTitle"
                    onChange={(e) => setPhone(e.target.value)}
                />
                </div> */}
            <div className="" style={{display:'flex', flexDirection:'column', justifyContent:"space-between", marginBottom:'.4rem'}}>
                <label htmlFor="email-titile">Slogan</label>
                <input
                    style={{height:'30px', padding:"0 1rem"}}
                    type="text"
                    id="email-title"
                    name="emailTitle"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                </div>
            <div className="" style={{display:'flex', flexDirection:'column', justifyContent:"space-between", marginBottom:'.4rem'}}>
                <label htmlFor="email-titile">Website</label>
                <input
                    style={{height:'30px', padding:"0 1rem"}}
                    type="text"
                    id="email-title"
                    name="emailTitle"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                />
                </div>
            <div className="" style={{display:'flex', flexDirection:'column', justifyContent:"space-between", marginBottom:'.4rem'}}>
                <label htmlFor="email-titile">Orgasation</label>
                <input
                    style={{height:'30px', padding:"0 1rem"}}
                    type="text"
                    id="email-title"
                    name="emailTitle"
                    value={org}
                    onChange={(e) => setOrg(e.target.value)}
                />
                </div>

                </div>


                </div>
            
            
                <div className="email-form-input">
                <label htmlFor="email-titile">Add Title</label>
                <ReactQuill
                    id="email-description"
                    name="emailDescription"
                    value={emailTitle}
                    onChange={setEmailTitle}
                    modules={modules}
                    formats={formats}
                    style={{ width: '450px' }}
                    placeholder="Write your email description here..."
                />
                {/* <textarea
                    type="text"
                    id="email-title"
                    name="emailTitle"
                    value={emailTitle}
                    onChange={(e) => setEmailTitle(e.target.value)}
                /> */}
                </div>
                <div className="email-form-input">
                <label htmlFor="email-description">Add Short Description</label>
                {/* <ReactQuill theme="snow" value={emailDescription}   onChange={(e) => setEmailDescription(e.target.value)} /> */}
                <ReactQuill
                    id="email-description"
                    name="emailDescription"
                    value={emailDescription}
                    onChange={setEmailDescription}
                    modules={modules}
                    formats={formats}
                    style={{ width: '450px' }}
                    placeholder="Write your email description here..."
                />
                {/* <textarea
                    type="text"
                    id="email-description"
                    name="emailDescription"
                    value={emailDescription}
                    onChange={(e) => setEmailDescription(e.target.value)}
                /> */}
                </div>
                <div className="email-form-input">
                <label htmlFor="email-keywords">Add Key words</label>
                <textarea
                    type="text"
                    id="email-keywords"
                    name="emailKeywords"
                    onKeyUp={addTags}
                />
                <div className="keyword-tags-section">
                    <ul className="tag-wrapper">
                    {/* {emailKeywords.map((tags, index) => (
                        <li
                        className="keyword-tag"
                        key={index}
                        >
                        {tags}
                        <button
                            onClick={() => removeTag(index)}
                            className="tag-close-btn"
                        >
                            x
                        </button>
                        </li>
                    ))} */}
                    </ul>
                </div>
                </div>

                </div>

                
                <div className="email-form-input">
          <select className="" onChange={(e) => setDisplayField(e.target.value)}>
            <option value="">Add Content</option>
            <option value="text">Type text</option>
            <option value="upload">Upload</option>
          </select>
          {
            (displayField === "upload") ? (
              <div style={{width: '450px', height: '50px', border: '2px dotted #111'}}>
                <input type="file" accept="appliction/pdf" onChange={(e) => setPDFFile(e.target.files[0])} required hidden ref={fileInputRef}/>
                <button onClick={handleUploadPdf} type="button">Click to upload only pdf file</button>
              </div>
            ) :  (
              <ReactQuill
              value={content}
              onChange={setContent}
              style={{ width: '450px' }}
              placeholder="Write your email description here..."
            />
              // <textarea type="text" onChange={(e) => setAddContent(e.target.value)}></textarea>
            )
          }
           <p>{pdfFile.name}</p>

        </div>
          {/* <div>
          <select className="" onChange={(e) => setMediaDisplay(e.target.value)} style={{width: '100%'}}> 
            <option value="">Add Media</option>
            <option value="image">Images</option>
            <option value="logo">Logo</option>
            <option value="banner">Footer Banner</option>
          </select>
             
             {
               <div style={{width: '450px', height: '50px', border: '2px dotted #111'}}>
                   { mediaDisplay ? <FileUpload name={mediaDisplay} /> : null}
             </div>
             }
          </div> */}
        
        <div>
        <div className="email-form-input">
            <label htmlFor="email-titile">Footer</label>
        </div>
        <div  style={{ display:'flex', flexWrap:'wrap', width:'450px', justifyContent:'space-between'}}>
        <div className="" style={{display:'flex', flexDirection:'column', justifyContent:"space-between", marginBottom:'.4rem'}}>
          <label htmlFor="email-titile">Signature</label>
          <input
            style={{height:'30px', padding:"0 1rem"}}
            type="text"
            id="email-title"
            name="emailTitle"
            value={signature}
            onChange={(e) => setSignature(e.target.value)}
          />
        </div>
      <div className="" style={{display:'flex', flexDirection:'column', justifyContent:"space-between", marginBottom:'.4rem'}}>
          <label htmlFor="email-titile">Author Name</label>
          <input
            style={{height:'30px', padding:"0 1rem"}}
            type="text"
            id="email-title"
            name="emailTitle"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
          />
        </div>
      <div className="" style={{display:'flex', flexDirection:'column', justifyContent:"space-between", marginBottom:'.4rem'}}>
          <label htmlFor="email-titile">Position</label>
          <input
            style={{height:'30px', padding:"0 1rem"}}
            type="text"
            id="email-title"
            name="emailTitle"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
          />
        </div>
      {/* <div className="" style={{display:'flex', flexDirection:'column', justifyContent:"space-between", marginBottom:'.4rem'}}>
          <label htmlFor="email-titile">Organisation</label>
          <input
            style={{height:'30px', padding:"0 1rem"}}
            type="text"
            id="email-title"
            name="emailTitle"
          />
        </div> */}
      

        
      

        </div>
        {/* <div className="email-form-input">
            <label htmlFor="email-titile">Email Settings</label>
        </div>



        <div className="email-form-input">
            <label htmlFor="email-titile">Footer Settings</label>
        </div> */}
       
        <div className="email-btns">
          <span onClick={saveEdit} style={{padding:'.5rem 2rem', borderRadius:".5rem", fontSize:'1rem', cursor:"pointer"}}>Save</span>
        </div>


        </div>
            </form>



         {/* </div> */}
         
        
        </div>
  );
}

export default EditEmail;
