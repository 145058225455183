import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./dynamic_card.css"; // Import the CSS file

const DynamicCard = ({
  title,
  recipient,
  dueDate,
  scheduleIcon,
  buttonColor,
  buttonText,
  buttonTextColor,
  separationBar,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Close dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="dynamic-card">
      <div className="dynamic-card-header">
        <div className="dynamic-card-content">
          <div>
            <img
              src={scheduleIcon}
              style={{ height: "150px", width: "150px" }}
              alt="Schedule Icon"
            />
          </div>
          <div>
            <h6 className="dynamic-card-title">{title}</h6>
            <p>{recipient}</p>
            <p>{dueDate}</p>
          </div>
        </div>
        <div ref={dropdownRef} className="dynamic-card-dropdown">
          <button
            onClick={toggleDropdown}
            className="dynamic-card-button"
            style={{
              backgroundColor: buttonColor,
              color: buttonTextColor,
            }}
          >
            <span>{buttonText}</span>
            <span
              className="dropdown-separator"
              style={{ borderLeftColor: separationBar }}
            >
              ▼
            </span>
          </button>

          {isOpen && (
            <div className="dynamic-card-dropdown-content">
              <ul className="dynamic-card-dropdown-list">
                <li className="dynamic-card-dropdown-item">Option 1</li>
                <li className="dynamic-card-dropdown-item">Option 2</li>
                <li className="dynamic-card-dropdown-item">Option 3</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DynamicCard;
