import "./marketing.css";
import { Link, Outlet, useLocation } from "react-router-dom";


import WireFrame from "../../Components/DasboardOverview/WireFrame";




export default function Marketing() {
  const marketingMenu = [
    "Leads",
    "Email",
    "SMS",
    "Survey",
    "Webinar",
    "Sales",
    "Reports",
  ];

  const { pathname } = useLocation();
  const selected = !pathname.split("/")[2] ? "leads" : pathname.split("/")[2];
  return (
    <>
    <WireFrame page={'marketting'}>
    <div className="right-blog">
        <div className="marketing-overview">
          <div className="top-section">
            <h2>Marketing Overview</h2>
            <div className="check">
              <h3>Health Check</h3>
              <label className="">
                <div className="text-alt">
                  <h4>30 Emails Sent</h4>
                  <h4>10 scheduled</h4>
                </div>
                <div className="progress">
                  <div
                    className="bar"
                    style={{
                      width: "50%",
                    }}
                  ></div>
                </div>
              </label>
            </div>
          </div>
          <div className="bottom-section">
            <div className="breadcrumbs">
              <span>Marketing {">"}</span> <span>{selected}</span>
            </div>
            <div className="marketing-menu">
              {marketingMenu.map((item) => (
                <Link
                  className={selected === item.toLowerCase() && "active"}
                  to={
                    item === "Leads"
                      ? "/marketing"
                      : item.toLowerCase()
                  }
                  key={item}
                >
                  {item}
                </Link>
              ))}
            </div>
          </div>
        </div>
        <Outlet />
      </div>


    </WireFrame>
     
    </>
  );
}
