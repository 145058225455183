import { useState, useEffect, useRef, useCallback, useMemo, memo } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CiSearch } from "react-icons/ci";
import { VscSettings } from "react-icons/vsc";
import "./segment.css";
import CreateSegment from "./CreateSegment/CreateSegment";
import general from "../../api/general";
import moment from "moment";

export default function Segment() {
  const [actionModal, setActionModal] = useState(null);
  const [filter, setFilter] = useState("");
  const [createModal, setCreateModal] = useState(false);
  const [segments, setSegments] = useState([]);
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchSegments = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await general.get("/email/getall-segment");
      setSegments(response.data);
    } catch (error) {
      console.error("Error fetching segments:", error);
      setSegments([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSegments();
  }, [fetchSegments]);

  const filteredAndSortedSegments = useMemo(() => {
    if (!segments) return [];
    let filteredSegments = segments;
    if (filter === "1") {
      filteredSegments = segments.filter((segment) => segment.status === "1");
    } else if (filter === "2") {
      filteredSegments = segments.filter((segment) => segment.status === "2");
    }

    return filteredSegments
      .filter(
        (segment) =>
          segment.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          moment(segment.created_at).format("MMMM Do YYYY").includes(searchTerm)
      )
      .sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      });
  }, [segments, searchTerm, sortOrder, filter]);

  const toggleCreateModal = () => setCreateModal((prev) => !prev);
  const handleSearchChange = (e) => setSearchTerm(e.target.value);

  return (
    <>
      {createModal && <CreateSegment setCreateModal={setCreateModal} />}
      <div className="table">
        <div className="table-filter">
          <label>
            <input type="checkbox" />
            <span>All</span>
          </label>

          <FilterModal setFilter={setFilter} />
          <div className="search">
            <div className="search-container">
              <input
                type="search"
                placeholder="Search by date or name"
                onChange={handleSearchChange}
              />
              <CiSearch />
            </div>
            <SortModal setSortOrder={setSortOrder} />
          </div>
          <div className="segment-column">
            <ColumnModal />
            <button onClick={toggleCreateModal}>Create Segment</button>
          </div>
        </div>
        <div className="table-component" role="region" tabIndex="0">
          {isLoading ? (
            <div>Loading...</div>
          ) : filteredAndSortedSegments.length === 0 ? (
            <div>No segments found</div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Segment</th>
                  <th>Date Created</th>
                  <th>Click Rate</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredAndSortedSegments.map((segment) => (
                  <SegmentRow
                    key={segment.id}
                    segment={segment}
                    actionModal={actionModal}
                    setActionModal={setActionModal}
                  />
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}

const SegmentRow = memo(({ segment, actionModal, setActionModal }) => (
  <tr>
    <td>
      <input type="checkbox" name="option1" value="option1" />
    </td>
    <td>{segment.name}</td>
    <td>{moment(segment.created_at).format("MMMM Do YYYY")}</td>
    <td>{segment.clickRate}</td>
    <td className="action">
      <button
        onClick={() =>
          setActionModal(actionModal === segment.id ? null : segment.id)
        }
      >
        <BsThreeDotsVertical />
      </button>
      {actionModal === segment.id && (
        <ActionModal setActionModal={setActionModal} />
      )}
    </td>
  </tr>
));

function ActionModal({ setActionModal }) {
  const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setActionModal(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setActionModal]);

  return (
    <div ref={modalRef} className="action-modal">
      <div>Export Segment</div>
      <div>Edit Segment</div>
      <div>Archive Segment</div>
      <div>Delete Segment</div>
    </div>
  );
}

function ColumnModal() {
  const [open, setOpen] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleOpen = () => setOpen(!open);

  return (
    <div className="column">
      <button onClick={toggleOpen}>Add Column</button>
      {open && (
        <div ref={modalRef} className="column-modal">
          <label>
            <input type="checkbox" />
            Segment Name
          </label>
          <label>
            <input type="checkbox" />
            Date Created
          </label>
          <label>
            <input type="checkbox" />
            Click Rate
          </label>
          <label>
            <input type="checkbox" />
            Open Rate
          </label>
          <div className="column-actions">
            <button onClick={() => setOpen(false)}>Cancel</button>
            <button>Add</button>
          </div>
        </div>
      )}
    </div>
  );
}

function FilterModal({ setFilter }) {
  const [open, setOpen] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFilter = useCallback(
    (type) => {
      setFilter(type);
      setOpen(false);
    },
    [setFilter]
  );

  const toggleOpen = () => setOpen(!open);

  return (
    <div className="filter">
      <button onClick={toggleOpen}>Filter Segment</button>
      {open && (
        <div ref={modalRef} className="filter-modal">
          <label onClick={() => handleFilter("")}>View All</label>
          <label onClick={() => handleFilter("1")}>View Created</label>
          <label onClick={() => handleFilter("2")}>View Archived</label>
        </div>
      )}
    </div>
  );
}

function SortModal({ setSortOrder }) {
  const [open, setOpen] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSort = useCallback(
    (order) => {
      setSortOrder(order);
      setOpen(false);
    },
    [setSortOrder]
  );

  const toggleOpen = () => setOpen(!open);

  return (
    <div className="sort">
      <button onClick={toggleOpen}>
        <VscSettings />
      </button>
      {open && (
        <div ref={modalRef} className="sort-modal">
          <label onClick={() => handleSort("asc")}>
            Sort by Date (Ascending)
          </label>
          <label onClick={() => handleSort("desc")}>
            Sort by Date (Descending)
          </label>
        </div>
      )}
    </div>
  );
}
