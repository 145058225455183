import React, { useEffect, useReducer, useState } from "react";
import axios from "axios";
import "./newsection.css"; // Import the new CSS file
import DynamicCardContainer from "../../Components/Marketing/schedule/dynamic-card";
import DateTimePicker from "./calendar";
import EmailSelection from "./emailSelection";
import SegmentSelection from "./segmentSelect";

// Define the initial state
const initialState = {
  step: 1,
  date: new Date(),
  hour: "",
  minute: "",
  amPm: "AM",
  selectedEmail: { id: null, title: "" },
  selectedSegement: { id: null, name: "" },
  schedule: false,
  campaignName: "",
  campaignNameError: "",
  loading: false,
  successMessage: "",
  emails: [], // Emails will be stored here after fetching
  segments: [], // Segments will be stored here after fetching
};

// Reducer function to handle state transitions
function reducer(state, action) {
  switch (action.type) {
    case "SET_CAMPAIGN_NAME":
      return { ...state, campaignName: action.payload };
    case "SET_CAMPAIGN_NAME_ERROR":
      return { ...state, campaignNameError: action.payload };
    case "SET_DATE":
      return { ...state, date: action.payload };
    case "SET_TIME":
      return { ...state, [action.payload.type]: action.payload.value };
    case "SET_SELECTED_EMAIL":
      return {
        ...state,
        selectedEmail: { id: action.payload.id, title: action.payload.title }, // Update to store as an object
      };
    case "SET_SELECTED_SEGMENT":
      return {
        ...state,
        selectedSegement: { id: action.payload.id, name: action.payload.name },
      };
    case "SET_STEP":
      return { ...state, step: action.payload };
    case "SCHEDULE":
      return { ...state, schedule: true };
    case "SET_EMAILS":
      return { ...state, emails: action.payload };
    case "SET_SEGMENTS":
      return { ...state, segments: action.payload };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_SUCCESS_MESSAGE":
      return { ...state, successMessage: action.payload };
    default:
      return state;
  }
}

export default function NewPublish() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [scheduleState, setScheduleState] = useState(false);
  const [autoState, setAutoState] = useState(false);

  // Fetch the email data from the API using Axios
  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const response = await axios.get(
          "https://ihubbackend2.xcelsz.com/api/email/getall-email"
        );
        const emailData = response.data; // Assuming the data is an array of emails
        console.log(emailData);
        dispatch({ type: "SET_EMAILS", payload: emailData });
      } catch (error) {
        console.error("Error fetching emails:", error);
      }
    };

    fetchEmails();
  }, []);

  useEffect(() => {
    const fetchSegments = async () => {
      try {
        const response = await axios.get(
          "https://ihubbackend2.xcelsz.com/api/email/getall-segment"
        );
        const segmentData = response.data;
        console.log(segmentData);
        dispatch({ type: "SET_SEGMENTS", payload: segmentData });
      } catch (error) {
        console.error("Error fetching emails:", error);
      }
    };
    fetchSegments();
  }, []);

  // Fetch the email data from the API using Axios
  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const response = await axios.get(
          "https://ihubbackend2.xcelsz.com/api/email/getall-email"
        );
        const emailData = response.data; // Assuming the data is an array of emails
        console.log(emailData);
        dispatch({ type: "SET_EMAILS", payload: emailData });
      } catch (error) {
        console.error("Error fetching emails:", error);
      }
    };

    fetchEmails();
  }, []);

  useEffect(() => {
    const fetchSegments = async () => {
      try {
        const response = await axios.get(
          "https://ihubbackend2.xcelsz.com/api/email/getall-segment"
        );
        const segmentData = response.data;
        console.log(segmentData);
        dispatch({ type: "SET_SEGMENTS", payload: segmentData });
      } catch (error) {
        console.error("Error fetching emails:", error);
      }
    };
    fetchSegments();
  }, []);

  const handleScheduleState = () => {
    setScheduleState(true);
    setAutoState(false);
  };

  const handleAutoState = () => {
    setAutoState(true);
    setScheduleState(false);
  };

  const handleStopSchedule = () => {
    setScheduleState(false);
  };
  
  const handleStopAuto = () => {
    setAutoState(false);
  };

  const handleTimeChange = (e, type) => {
    dispatch({ type: "SET_TIME", payload: { type, value: e.target.value } });
  };

  const handleDateChange = (newDate) => {
    dispatch({ type: "SET_DATE", payload: newDate });
  };

  const handleNextClick = async () => {
    if (state.step === 1 && !state.campaignName.trim()) {
      dispatch({
        type: "SET_CAMPAIGN_NAME_ERROR",
        payload: "Please enter a campaign name.",
      });
      return;
    } else {
      dispatch({ type: "SET_CAMPAIGN_NAME_ERROR", payload: "" });
    }

    if (state.step === 2 && scheduleState && (!state.hour || !state.minute || !state.date)) {
      alert("Please select a valid date and time to continue.");
      return;
    }

    if (state.step === 3 && !state.selectedEmail.id) {
      alert("Please select an email before proceeding.");
      return;
    }

    if (state.step === 4 && !state.selectedSegement.id) {
      alert("Please select a segment before scheduling.");
      return;
    }

    if (state.step === 4) {
      // Format the date and time to "YYYY-MM-DDTHH:mm" (24-hour format)
      const scheduledDate = new Date(
        state.date.getFullYear(),
        state.date.getMonth(),
        state.date.getDate(),
        state.amPm === "PM" ? parseInt(state.hour) + 12 : state.hour, // Convert to 24-hour format if PM
        state.minute
      )
        .toISOString()
        .slice(0, 16);

      const payload = {
        recipients: [
          "princeaikinsbaidoo@xcelsz.com",
          "baidooprinceaikins@gmail.com",
          "kingsharp.ampedu-nkansah@xcelsz.com",
          "kingsharpn3@gmail.com",
          "baidooprinceaikins@outloook.com",
        ],
        subject: state.campaignName,
        message:
          "This is a test email scheduled to be sent at a specific time.",
        scheduledDate: scheduledDate,
        timeZone: "UTC",
        template_id: state.selectedEmail.id,
        segement: state.selectedSegement.id, // Corrected to use selected segment
      };

      dispatch({ type: "SET_LOADING", payload: true });
      try {
        const response = await axios.post(
          "https://ihubbackend2.xcelsz.com/api/email/schedule-email",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Email scheduled successfully:", response);
        dispatch({
          type: "SET_SUCCESS_MESSAGE",
          payload: "Email scheduled successfully!",
        }); // Set success message
        setTimeout(
          () => dispatch({ type: "SET_SUCCESS_MESSAGE", payload: "" }),
          5000
        );
      } catch (error) {
        console.error("Error scheduling email:", error);
        if (error.response) {
          console.error("Error scheduling email:", error.response.data); // Logs backend response error
        } else if (error.request) {
          console.error("No response received:", error.request); // Logs no response from the server
        } else {
          console.error("Error setting up request:", error.message); // Logs general errors
        }
      } finally {
        dispatch({ type: "SET_LOADING", payload: false });
      }
    }

    if (state.step < 4) {
      dispatch({ type: "SET_STEP", payload: state.step + 1 });
    }
  };

  const handleBackClick = () => {
    if (state.step > 1) {
      dispatch({ type: "SET_STEP", payload: state.step - 1 });
    }
  };

  const handleEmailSelect = (id, title) => {
    console.log("selected email Id ", id);
    dispatch({ type: "SET_SELECTED_EMAIL", payload: { id, title } });
  };

  const handleSegmentSelected = (id, name) => {
    console.log("Selected email Id", id);
    dispatch({ type: "SET_SELECTED_SEGMENT", payload: { id, name } });
  };



  const autoSendRocket = async ()=>{
    const payload = {
      title: state.campaignName,
      message:
        "This is a test email scheduled to be sent at a specific time.",
      template_id: state.selectedEmail.id,
      segement: state.selectedSegement.id, // Corrected to use selected segment
    };

    try {
      const response = await axios.post(
        "https://ihubbackend2.xcelsz.com/api/email/sendEmail",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Email scheduled successfully:", response);
      dispatch({
        type: "SET_SUCCESS_MESSAGE",
        payload: "Email scheduled successfully!",
      }); // Set success message
      setTimeout(
        () => dispatch({ type: "SET_SUCCESS_MESSAGE", payload: "" }),
        5000
      );
    } catch (error) {
      console.error("Error scheduling email:", error);
      if (error.response) {
        console.error("Error scheduling email:", error.response.data); // Logs backend response error
      } else if (error.request) {
        console.error("No response received:", error.request); // Logs no response from the server
      } else {
        console.error("Error setting up request:", error.message); // Logs general errors
      }
    } finally {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  }

  return (
    <div className="new_publish_container">
      <div className="new_publish_top-section">
        <div>
          <h5 className="new_publish_heading">New Campaigns</h5>
        </div>
        <div className="new_publish_button-container">
          <button className="new_publish_button" onClick={handleScheduleState}>
            Schedule
          </button>
          <button className="new_publish_button new_publish_button-black" onClick={handleAutoState}>
            AutoSend
          </button>
        </div>
      </div>
      {state.loading && (
        <div className="loading-spinner">
          <div className="spinner"></div>
        </div>
      )}

      {scheduleState && (
        <div className="new_publish_new_div">
          <div className="new_publish_new_subdiv">
            <h2 className="new_publish_heading first_heading_h2">
              Schedule to Publish
            </h2>
            <div className="new_publish_step_master">
              {state.successMessage && (
                <div className="success-message">{state.successMessage}</div>
              )}
              {state.step === 1 && (
                <>
                  <h6 className="step_one_first_heading_h6">
                    Enter Campaign name
                  </h6>
                  <div className="new_publish_campaign_div">
                    <input
                      type="text"
                      required
                      placeholder="Type campaign name..."
                      value={state.campaignName}
                      onChange={(e) =>
                        dispatch({
                          type: "SET_CAMPAIGN_NAME",
                          payload: e.target.value,
                        })
                      }
                    />
                    {state.campaignNameError && (
                      <p className="error">{state.campaignNameError}</p>
                    )}
                  </div>
                </>
              )}

              {state.step === 2 && (
                <>
                  <DateTimePicker
                    date={state.date}
                    setDate={handleDateChange}
                    hour={state.hour}
                    minute={state.minute}
                    amPm={state.amPm}
                    handleTimeChange={handleTimeChange}
                  />
                </>
              )}
              {state.step === 3 && (
                <>
                  <h6 className="step_one_first_heading_h6">
                    Review Selected Date and Time
                  </h6>
                  <div className="new_publish_date_master">
                    <h6>{state.date.toDateString()}</h6>
                    <h6>
                      {state.hour}:{state.minute.padStart(2, "0")} {state.amPm}
                    </h6>
                  </div>

                  {/* Display just the email */}
                  {state.emails && state.emails.length > 0 && (
                    <EmailSelection
                      savedEmails={state.emails} // Pass the whole email data array
                      selectedEmail={state.selectedEmail}
                      handleEmailSelect={handleEmailSelect}
                    />
                  )}
                </>
              )}
              {state.step === 4 && (
                <>
                  <h6 className="step_one_first_heading_h6">
                    Review Selected Date and Time
                  </h6>
                  <div className="new_publish_date_master">
                    <h6>{state.date.toDateString()}</h6>
                    <h6>
                      {state.hour}:{state.minute.padStart(2, "0")} {state.amPm}
                    </h6>
                  </div>

                  {/* Display just the email */}
                  {state.segments && state.segments.length > 0 && (
                    <SegmentSelection
                      savedSegment={state.segments} // Pass the whole email data array
                      selectedSegment={state.selectedSegement}
                      handleSegmentSelect={handleSegmentSelected}
                    />
                  )}
                </>
              )}

              <div className="step_last_div">
                {state.step > 1 && (
                  <div className="step_greater_one">
                    <button
                      onClick={handleBackClick}
                      className="new_publish_card_button_back"
                    >
                      Back
                    </button>
                  </div>
                )}
                <div className="step_last_div">
                  <button
                    onClick={handleNextClick}
                    className="new_publish_card_button"
                    disabled={
                      (state.step === 1 && !state.campaignName.trim()) || // Disable if no campaign name
                      (state.step === 3 && !state.selectedEmail.id) || // Disable if no email is selected
                      (state.step === 4 && !state.selectedSegement.id) // Disable if no segment is selected
                    }
                  >
                    {state.step === 4 ? "Schedule" : "Next"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="cancel_button">
            <button onClick={handleStopSchedule}>Cancel</button>
          </div>
        </div>
      )}

{autoState && (
        <div className="new_publish_new_div">
          <div className="new_publish_new_subdiv">
            <h2 className="new_publish_heading first_heading_h2">
            AutoSend to Publish
            </h2>
            <div className="new_publish_step_master">
              {state.successMessage && (
                <div className="success-message">{state.successMessage}</div>
              )}
              {state.step === 1 && (
                <>
                  <h6 className="step_one_first_heading_h6">
                    Enter Campaign name
                  </h6>
                  <div className="new_publish_campaign_div">
                    <input
                      type="text"
                      required
                      placeholder="Type campaign name..."
                      value={state.campaignName}
                      onChange={(e) =>
                        dispatch({
                          type: "SET_CAMPAIGN_NAME",
                          payload: e.target.value,
                        })
                      }
                    />
                    {state.campaignNameError && (
                      <p className="error">{state.campaignNameError}</p>
                    )}
                  </div>
                </>
              )}

{state.step === 2 && (
                <>
                  
                  {/* Display just the email */}
                  {state.emails && state.emails.length > 0 && (
                    <EmailSelection
                      title='Saved Emails'
                      savedEmails={state.emails} // Pass the whole email data array
                      selectedEmail={state.selectedEmail}
                      handleEmailSelect={handleEmailSelect}
                    />
                  )}
                </>
              )}

            
              {state.step === 3 && (
                <>

                  {/* Display just the email */}
                  {state.segments && state.segments.length > 0 && (
                    <SegmentSelection
                      title='Saved Segment'
                      savedSegment={state.segments} // Pass the whole email data array
                      selectedSegment={state.selectedSegement}
                      handleSegmentSelect={handleSegmentSelected}
                    />
                  )}
                </>
              )}

              <div className="step_last_div">
                {state.step > 1 && (
                  <div className="step_greater_one">
                    <button
                      onClick={handleBackClick}
                      className="new_publish_card_button_back"
                    >
                      Back
                    </button>
                  </div>
                )}
                <div className="step_last_div">
                  {state.step !== 3 ?
                  <button
                    onClick={handleNextClick}
                    className="new_publish_card_button"
                    disabled={
                      (state.step === 1 && !state.campaignName.trim()) || // Disable if no campaign name
                      (state.step === 3 && !state.selectedEmail.id) || // Disable if no email is selected
                      (state.step === 4 && !state.selectedSegement.id) // Disable if no segment is selected
                    }
                  >
                    {state.step === 3 ? "Schedule" : "Next"}
                  </button>

                  :
                  <button
                  onClick={autoSendRocket}
                  className="new_publish_card_button"
                 
                >
                 Schedule
                </button>

                }
                </div>
              </div>
            </div>
          </div>
          <div className="cancel_button">
            <button onClick={handleStopAuto}>Cancel</button>
          </div>
        </div>
      )}

      <div className="new_publish_dynamic_card">
        <h5 className="new_publish_heading">Recently Published</h5>
        <DynamicCardContainer />
      </div>
    </div>
  );
}
