import React, { useState } from "react";
import ScheduleMenu from "../../Components/Marketing/schedule/schedule";
import "./header.css";
import NewPublish from "./newsection";

export default function PublishHead() {
  const sectionMenu = ["New", "AutoSent", "Scheduled", "Archived"];
  const [section, setSection] = useState("New");

  return (
    <div classname="heading_master_div">
      <div className="navigation_bar_headers">
        {sectionMenu.map((item) => (
          // Add return here
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setSection(item)}
            className={section === item ? "active-header" : ""}
            key={item}

          >
            {item}
          </span>
        ))}
      </div>
      {section==="New" &&<NewPublish/>}
      {section === "Scheduled" && <ScheduleMenu />}
    </div>
  );
}
