import React from "react";
import { FaPlus } from "react-icons/fa";

export default function MultipleSegment({
  inputs,
  setInputs,
  setStep,
  setMultipleStep,
  multipleStep,
  selectedId,
  createMultipleSegment,
}) {
  const updateMultipleData = (field, newValue) => {
    const updatedMultipleData = inputs.multipleData.map((item) =>
      item.id === selectedId ? { ...item, [field]: newValue } : item
    );
    setInputs((prev) => ({
      ...prev,
      multipleData: updatedMultipleData,
    }));
  };

  const deleteMultipleData = (field) => {
    const updatedMultipleData = inputs.multipleData.map((item) =>
      item.id === selectedId ? { ...item, [field]: "" } : item
    );
    setInputs((prev) => ({
      ...prev,
      multipleData: updatedMultipleData,
    }));
  };

  const getSelectedItemField = (field) => {
    const selectedItem = inputs.multipleData.find(
      (item) => item.id === selectedId
    );
    return selectedItem ? selectedItem[field] : null;
  };

  const renderSegmentCategory = () => (
    <>
      <div className="segment-category">
        <label
          onClick={() => updateMultipleData("segmentCategory", "user role")}
          className={getSelectedItemField("segmentCategory") === "user role" && "active"}
        >
          User Role
        </label>
        <label
          onClick={() => updateMultipleData("segmentCategory", "lead quality")}
          className={getSelectedItemField("segmentCategory") === "lead quality" && "active"}
        >
          Lead Quality
        </label>
      </div>
      <div className="modal-actions">
        <button
          onClick={() => {
            deleteMultipleData("segmentCategory");
            setInputs((prev) => ({ ...prev, multipleData: [] }));
            setStep("segmentType");
          }}
        >
          Back
        </button>
        <button
          onClick={() => setMultipleStep("targetMarket")}
          disabled={!getSelectedItemField("segmentCategory")}
        >
          Next
        </button>
      </div>
    </>
  );

  const renderUserRoleTargetMarket = () => (
    <div className="target-market">
      <div className="top">
        <h3>Multiple Audience</h3>
        <div className="modal-actions">
          <button
            onClick={() => {
              deleteMultipleData("userRole");
              setMultipleStep("segmentCategory");
            }}
          >
            Back
          </button>
          <button
            onClick={() => setMultipleStep("final")}
            disabled={
              !getSelectedItemField("characteristic") || !getSelectedItemField("targetMarket")
            }
          >
            Next
          </button>
        </div>
      </div>
      <select
        onChange={(e) => updateMultipleData("userRole", e.target.value)}
        value={getSelectedItemField("userRole")}
      >
        <option disabled>Choose User Role</option>
        <option value="individual">Individual</option>
        <option value="business">Business</option>
      </select>
      {getSelectedItemField("userRole") && (
        <>
          <select
            onChange={(e) => updateMultipleData("targetMarket", e.target.value)}
            value={getSelectedItemField("targetMarket")}
          >
            <option value="" disabled>
              Select Target Market
            </option>
            {getSelectedItemField("userRole") === "individual" ? (
              <>
                <option value="Diaspora">Diaspora</option>
                <option value="Professional">Professional</option>
              </>
            ) : (
              <>
                <option value="Startups">Startups</option>
                <option value="SMEs">SMEs</option>
                <option value="association">Association</option>
              </>
            )}
          </select>
          <select
            onChange={(e) => updateMultipleData("characteristic", e.target.value)}
            value={getSelectedItemField("characteristic")}
          >
            <option value="" disabled>
              Select Characteristic
            </option>
            {getSelectedItemField("userRole") === "individual" ? (
              <>
                <option value="Low Income">Low Income</option>
                <option value="Mid Income">Mid Income</option>
                <option value="High Income">High Income</option>
              </>
            ) : (
              <>
                <option value="Low Turnover">Low Turnover</option>
                <option value="Mid Turnover">Mid Turnover</option>
                <option value="High Turnover">High Turnover</option>
              </>
            )}
          </select>
        </>
      )}
    </div>
  );

  const renderLeadQualityTargetMarket = () => (
    <div className="final">
      <div className="top">
        <h3>Multiple Audience</h3>
        <div className="modal-actions">
          <button
            onClick={() => {
              deleteMultipleData("leadQuality");
              setMultipleStep("segmentCategory");
            }}
          >
            Back
          </button>
          <button
            onClick={() => setMultipleStep("final")}
            disabled={!getSelectedItemField("leadQuality")}
          >
            Next
          </button>
        </div>
      </div>
      <select
        onChange={(e) => updateMultipleData("leadQuality", e.target.value)}
        value={getSelectedItemField("leadQuality")}
      >
        <option disabled>Choose Lead Quality</option>
        <option value="cold leads">Cold Leads</option>
        <option value="warm leads">Warm Leads</option>
        <option value="hot leads">Hot Leads</option>
      </select>
    </div>
  );

  const renderFinal = () => (
    <div className="final">
      <div className="top">
        <h3>Select Add leads email option</h3>
        <div className="modal-actions">
          <button onClick={() => setMultipleStep("targetMarket")}>
            Back
          </button>
          <button>Create</button>
        </div>
      </div>
      <select>
        <option disabled>Select Add leads email option</option>
        <option value="">Upload from file</option>
        <option value="">Copy and Paste</option>
        <option value="">Select from system</option>
      </select>
      <div className="tool-tip">
        <button
          onClick={() => {
            createMultipleSegment();
            setMultipleStep("segmentCategory");
          }}
        >
          <FaPlus />
        </button>
        <span>Add another audience</span>
      </div>
    </div>
  );

  return (
    <>
      {multipleStep === "segmentCategory" && renderSegmentCategory()}
      {multipleStep === "targetMarket" && getSelectedItemField("segmentCategory") === "user role" && renderUserRoleTargetMarket()}
      {multipleStep === "targetMarket" && getSelectedItemField("segmentCategory") === "lead quality" && renderLeadQualityTargetMarket()}
      {multipleStep === "final" && renderFinal()}
    </>
  );
}
