import React, { useState } from "react";
import schedule from "../../../assets/schedule.png";
import DynamicCard from "./schedulelist";
import DynamicCardContainer from "./dynamic-card";

const ScheduleMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div
      style={{
        margin: "1rem 2rem",
        display: "flex",
        flexDirection: "column",
        width: "96%",
        height: "100%",
        borderRadius: "15px",
        border: "1px solid #ccc",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      }}
    >
      <div
        style={{
          padding: "3rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h5 style={{ fontSize: "47px", fontFamily: "Lato" }}>Schedule List</h5>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "3rem",
            flexWrap: "wrap",
          }}
        >
          <div>
            <button
              style={{
                backgroundColor: "transparent",
                border: "1px solid black",
                color: "black",
                padding: "10px 20px",
                cursor: "pointer",
                outline: "none",
                transition: "background-color 0.3s, color 0.3s",
                width: "100%",
                maxWidth: "180px",
              }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "black";
                e.target.style.color = "white";
              }}
              onMouseOut={(e) => {
                e.target.style.backgroundColor = "transparent";
                e.target.style.color = "black";
              }}
            >
              See Archives
            </button>
          </div>
          <div>
            <button
              style={{
                backgroundColor: "black",
                border: "1px solid black",
                color: "white",
                padding: "10px 20px",
                cursor: "pointer",
                outline: "none",
                transition: "background-color 0.3s, color 0.3s",
                width: "100%",
                maxWidth: "180px",
              }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "white";
                e.target.style.color = "black";
              }}
              onMouseOut={(e) => {
                e.target.style.backgroundColor = "black";
                e.target.style.color = "white";
              }}
            >
              Auto Send
            </button>
          </div>
        </div>
      </div>
      <DynamicCardContainer />
      <DynamicCard
        title="Checkout Lastest Deals"
        recipient="Recipient name"
        dueDate="Monday, 20 Aug, 2024 @10:30am"
        scheduleIcon={schedule}
        buttonColor="black"
        buttonText="View Report"
        buttonTextColor="white"
        separationBar="white"
      />
      <DynamicCard
        title="Checkout Lastest Deals"
        recipient="Recipient name"
        dueDate="Monday, 20 Aug, 2024 @10:30am"
        scheduleIcon={schedule}
        buttonColor="black"
        buttonText="View Report"
        buttonTextColor="white"
        separationBar="white"
      />
    </div>
  );
};

export default ScheduleMenu;
