import React, { useEffect, useState, useRef } from "react";
import BigTriangle from "../../assets/BigTriangle.svg";
import SmallTriangle from "../../assets/SmallTriangle.svg";
import bannerImage from "../../assets/banner.png"
import world from "../../assets/world.svg"
import profile from "../../assets/profile.png"
import emailIcon from "../../assets/email.jpeg"
import call from "../../assets/call.png"
import { Link, Outlet,useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import EditEmail from "./EditEmail";

import axios from "axios";

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';



import emailFeature from "../../https/email";


const paginationModel = { page: 0, pageSize: 5 };






function EmailPreview() {
  const notify = (msg) => toast(msg);
  const [emailTitle, setEmailTitle] = useState("");
  const [emailDescription, setEmailDescription] = useState("");
  const [emailKeywords, setEmailKeywords] = useState([]);
  const [addContent, setAddContent] = useState("");
  const [logo, setLogo] = useState("");
  const [header, setHeader] = useState("");
  const [org, setOrg] = useState("");
  const [Website, setWebsite] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [emailImage, setEmailImage] = useState("");
  const [signature, setSignature] = useState("");
  const [author, setAuthor] = useState("");
  const [position, setPosition] = useState("");
  const [banner, setBanner] = useState("");
  const [sucess, setSucess] = useState("");
  const [fail, setFail] = useState("");
  const [savedData, setSavedData] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [editView, setEditView] = useState(false);






  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Close dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);



  const handleEdit = (data)=>{
    setViewData(data)
    setEditView(true)
  }

  const stripHTML = (html) => html.replace(/<[^>]*>?/gm, "");



  const rows = savedData.map((sale) => ({
    id: sale.id,
    logo: sale.logo,
    footerbanner: sale.footerbanner,
    title: stripHTML(sale.title),
    description: stripHTML(sale.description),
    content: stripHTML(sale.content),
    image: sale.image,
    author: sale.author,
    position: sale.position,
    organisation: sale.organisation,
    header: stripHTML(sale.header),
    social: sale.social,
    signature: sale.signature,
    keyword: sale.keyword,
    created_at: sale.created_at,
}));





const columns= [
  { field: 'header', headerName: 'header', width: 350 },
  { field: 'author', headerName: 'author', width: 200 },
  { field: 'created_at', headerName: 'created_at', width: 200 },
  // { field: 'actions', headerName: 'actions', width: 200 },
  {
    field: "edit",
    headerName: "Edit",
    width: 150,
    renderCell: (params) => (
        <button
            onClick={() => handleEdit(params.row)}
            style={{
                padding: "10px 20px",
                backgroundColor: "transparent",
                color: "#000000",
                border: "1px solid #000000",
                borderRadius: "4px",
                cursor: "pointer"
            }}
        >
            Edit
        </button>
    ),
},
  {
    field: "actions",
    headerName: "Actions",
    width: 200,
    renderCell: (params) => (
      <select name="" id=""
      style={{
        padding: "10px",
        backgroundColor: "#fac710",
        color: "#000000",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer"
    }}
      >
        <option value="">Publish</option>
        <option value="">AutoSend</option>
        <option value="">Schedule</option>
      </select>
    ),
},
  
];




  const navigate = useNavigate();


  // function to remove email keyword tags
  const removeTag = (indexToRemove) => {
    setEmailKeywords((prev) =>
      prev.filter((_, index) => index !== indexToRemove)
    );
  };

  //function to return key words
  const addTags = (e) => {
    const keyword = e.target.value.trim().replace(/\s+/g, "");
    if (e.key === "Enter") {
      setEmailKeywords((prev) => [...prev, keyword]);
      e.target.value = "";
    }
  };


  const saveEmail = async ()=>{
    const data={
      "logo": logo,
      "footerbanner": banner,
      "image": emailImage,
      "title": emailTitle,
      "header": header,
      "description": emailDescription,
      "content":addContent,
      "signature": signature,
      "author": author,
      "position": position,
      "organisation":org,
      "social":[
        {
          "Website":Website,
          "email":email,
          "phone":phone
        }
      ],
      "keyword":emailKeywords.join(",")
  }
  console.log(data);
  
    try {
      const res = await emailFeature.saveEmail(data);
      console.log(res);
      notify('Email Saved Sucessfully')
      navigate("/marketing/email/create");
    } catch (error) {
      console.log(error);
      notify('Email Failed')
    }
  }

  useEffect(()=>{
    console.log(phone);
    
  },[phone])

  // const [savedData, setSavedData] = useState([]);
  const sectionMenu = ["New", "Saved", "Template"];
  const [section, setSection] = useState("New");

  const getallListiingTypes = async () => {
    try {
      const response = await axios.get(
        "https://ihubbackend2.xcelsz.com/api/email/getall-email"
      );
      console.log(response.data);
      setSavedData(response.data)
      
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
useEffect(()=>{
  getallListiingTypes()
  
},[])

  return (
    <>
    {editView&& <EditEmail data={viewData} updateList = {getallListiingTypes} notify={notify} closeModel={()=>setEditView(false)}/>}
   
    <div className="navigation_bar_headers">
        {sectionMenu.map((item) => (
          // Add return here
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setSection(item)}
            className={section === item ? "active-header" : ""}
            key={item}

          >
            {item}
          </span>
        ))}
      </div>
      {section==="New" && 
      <div className="email-section" >
       <ToastContainer />
       
      <div className="email-preview-section">
        
        <p className="preview-title">Email Preview</p>

        <div className="preview-email">
          <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', background:"#FBF6E4", padding:'1rem 1rem', borderBottom:'3px solid #f1c40f'}}>
          {logo ? (
            <img
              src={logo}
              alt="email-logo"
              style={{ width: "92px", height: "92px", borderRadius: "50%" }}
            />
          ) : (
            <div className="email-logo"></div>
          )}

          <div>
              <div style={{marginBottom:'1rem', maxWidth:'300px' }}>
                {header?(
                  <h2
                  style={{ margin: '0 1rem' }}
                  dangerouslySetInnerHTML={{
                    __html: header || 'header',
                  }}
                />
                  //  <h2 style={{width:'100%'}}>{header}</h2>
                ):(
                  <h2 style={{width:'100%'}}>Header</h2>
                )}
             
              </div>
              
              {/* <div style={{display:"flex", justifyContent:"flex-end", marginBottom:".2rem"}}>
              {phone?(
                  <p style={{textAlign:"right", marginRight:'1rem', fontSize:".9rem", fontWeight:'bold'}}>{phone}</p>
                ):(
                  <p style={{textAlign:"right", marginRight:'1rem', fontSize:".9rem", fontWeight:'bold'}}>Phone</p>
                )}
              
              <img
              src={call}
              alt="email-logo"
              style={{ width: "20px", height: "20px", borderRadius: "50%" }}
            />
              </div>
              <div style={{display:"flex", justifyContent:"flex-end", marginBottom:".2rem"}}>
              {email?(
                  <p style={{textAlign:"right", marginRight:'1rem', fontSize:".9rem", fontWeight:'bold'}}>{email}</p>
                ):(
                  <p style={{textAlign:"right", marginRight:'1rem', fontSize:".9rem", fontWeight:'bold'}}>Email</p>
                )}
             
              <img
              src={emailIcon}
              alt="email-logo"
              style={{ width: "20px", height: "20px", borderRadius: "50%" }}
            />
              </div> */}
             
              <div style={{display:"flex", justifyContent:"flex-end", marginBottom:".2rem"}}>
              {org?(
                  <p style={{textAlign:"right", marginRight:'1rem', fontSize:".9rem", fontWeight:'bold'}}>{org}</p>
                ):(
                  <p style={{textAlign:"right", marginRight:'1rem', fontSize:".9rem", fontWeight:'bold'}}>Organisation</p>
                )}
              <img
              src={profile}
              alt="email-logo"
              style={{ width: "20px", height: "20px", borderRadius: "50%" }}
            />
              </div>


              <div style={{display:"flex", justifyContent:"flex-end", marginBottom:".2rem"}}>
              {email?(
                  <p style={{textAlign:"right", marginRight:'1rem', fontSize:".9rem", fontWeight:'bold'}}>{email}</p>
                ):(
                  <p style={{textAlign:"right", marginRight:'1rem', fontSize:".9rem", fontWeight:'bold'}}>Slogan</p>
                )}
             
              <img
              src={emailIcon}
              alt="email-logo"
              style={{ width: "20px", height: "20px", borderRadius: "50%" }}
            />
              </div>

              <div style={{display:"flex", justifyContent:"flex-end", marginBottom:".2rem"}}>
              {Website?(
                  <p style={{textAlign:"right", marginRight:'1rem', fontSize:".9rem", fontWeight:'bold'}}>{Website}</p>
                ):(
                  <p style={{textAlign:"right", marginRight:'1rem', fontSize:".9rem", fontWeight:'bold'}}>Website</p>
                )}
              <img
              src={world}
              alt="email-logo"
              style={{ width: "20px", height: "20px", borderRadius: "50%" }}
            />
              </div>


              {emailKeywords.length !==0 ?(
                <div style={{display:'flex', justifyContent:'flex-end'}}>
                  {emailKeywords.map((value, index)=>{
                    return(
                      <p key={index} style={{textAlign:"right", marginRight:'1rem', fontSize:".9rem", fontWeight:'bold'}}>{`#${value}`}</p>
                    )
                  
                })}
                </div>
              ) : (
                <p style={{textAlign:"right", marginRight:'1rem', fontSize:".9rem", fontWeight:'bold'}}>#keywords</p>
              )}
              

          </div>

          </div>
         
          <div className="preview-inner-section">
            {emailImage ? (
              <img src={emailImage} alt="email-image" style={{width: '100%'}} />
            ) : (
              <div className="email-image-wrapper">
                <img
                  src={SmallTriangle}
                  alt="email logo"
                  style={{ width: "20%" }}
                />
                <img
                  src={BigTriangle}
                  alt="email logo"
                  style={{ width: "40%" }}
                />
              </div>
            )}
          </div>
          <h3
                  style={{ margin: '0 1rem' }}
                  dangerouslySetInnerHTML={{
                    __html: emailTitle || 'Title of Email',
                  }}
                />
          {/* <h3 style={{margin:'0 1rem',}}>{emailTitle || "Title of Email"}</h3> */}
          {/* <p style={{margin:'0 1rem',}}>
            {emailDescription ||
              `Brief description`}
          </p> */}
          <div
                  style={{ margin: '0 1rem' }}
                  dangerouslySetInnerHTML={{
                    __html: emailDescription || 'Brief description',
                  }}
                />

          <p
            style={{ margin: '0 1rem' }}
            dangerouslySetInnerHTML={{
              __html: addContent || `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.`,
            }}/>
          {/* <p style={{margin:'0 1rem',}}>{addContent ||
            `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.`}</p> */}

          <div style={{ margin:'0 1rem', marginTop:"1rem"}}>
              {signature?(
                  <p style={{textAlign:"left", marginRight:'1rem', fontFamily:"cursive", marginBottom:"1rem"}}>{signature}</p>
                ):(
                  <p style={{textAlign:"left", marginRight:'1rem', fontFamily:"cursive", marginBottom:"1rem"}}>Signature</p>
              )}
              {author?(
                  <p style={{textAlign:"left", marginRight:'1rem', fontWeight:'bold', textTransform:"capitalize"}}>{author}</p>
                ):(
                  <p style={{textAlign:"left", marginRight:'1rem', fontWeight:'bold', textTransform:"capitalize"}}>Author</p>
              )}
              {position?(
                  <p style={{textAlign:"left", marginRight:'1rem', fontWeight:'bold', textTransform:"capitalize"}}>{position}</p>
                ):(
                  <p style={{textAlign:"left", marginRight:'1rem', fontWeight:'bold', textTransform:"capitalize"}}>Position</p>
              )}
              {org?(
                  <p style={{textAlign:"left", marginRight:'1rem', fontWeight:'bold', textTransform:"capitalize"}}>{org}</p>
                ):(
                  <p style={{textAlign:"left", marginRight:'1rem', fontWeight:'bold', textTransform:"capitalize"}}>Organisation</p>
              )}
              

          </div>

          <div className="preview-inner-section" style={{margin:'1rem 0'}}>
            <Link to={'#'}>
            {banner?(
              <img
              src={banner}
              alt="email-logo"
              style={{ width: "100%", height: "auto", }}
            />
            ):(
              <div className="email-image-wrapper">
                <img
                  src={SmallTriangle}
                  alt="email logo"
                  style={{ width: "20%" }}
                />
                <img
                  src={BigTriangle}
                  alt="email logo"
                  style={{ width: "40%" }}
                />
              </div>
            )}
              
            </Link>
          
          </div>
         
          <ul className="xcelsz-socials">
            <li></li>
          </ul>
        </div>
        <div className="email-btns">
          <Link to="page2">Next</Link>
          <Link to="">Cancel</Link>
        </div>
      </div>

      <Outlet
        context={{
          emailKeywords,
          emailTitle,
          emailDescription,
          addContent,
          logo,
          header,
          org,
          Website,
          email,
          phone,
          signature,
          author,
          position,
          banner,
          addTags,
          removeTag,
          setEmailTitle,
          setEmailDescription,
          setEmailKeywords,
          setAddContent,
          setLogo,
          setEmailImage,
          setBanner,
          setHeader,
          setOrg, 
          setWebsite,
          setEmail, 
          setPhone,
          setSignature,
          setAuthor, 
          setPosition,
          saveEmail
        }}
      />
    </div>}

    {section ==="Saved" && <div className="table">
        {/* filter div */}
      
        <div class="table-component" role="region" tabindex="0">
            <p>Saved List</p>
          {/* <table>
            <thead>
              <tr>
                <th></th>
                <th>Email Details</th>
                <th>Author</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
                {savedData.map((ele, index)=>{
                    return(
                        <tr key={index}>
                            <td>
                                <input type="checkbox" name="option1" value="option1" />
                            </td>
                            <td>
                                <p>{ele.header}</p> {ele.created_at}</td>
                            <td>{ele.author}</td>
                            <td className="action" > 
                                <span style={{ marginRight:"1rem", border:'1px solid #000000', padding:'.5rem',cursor:"pointer"}}>Preview</span>
                                <span style={{ marginRight:"1rem", border:'1px solid #000000', padding:'.5rem',cursor:"pointer"}}>Schedule</span>
                                <span onClick={()=>{sendEmail(ele)}} style={{ marginRight:"1rem", border:'1px solid #000000',background:'#FAC710', padding:'.5rem', cursor:"pointer"}}>Send</span>
                                
                            </td>
                        </tr>
                    )
                })}
            
            </tbody>
          </table> */}
          <Paper sx={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{ pagination: { paginationModel } }}
              pageSizeOptions={[5, 10]}
              checkboxSelection
              sx={{ border: 0 }}
            />
          </Paper>
        </div>
      </div>}
   
    
    </>
  );
}

export default EmailPreview;
