import { useState } from "react";
import general from "../../../api/general";

export default function SingleSegment({
  inputs,
  setInputs,
  setStep,
  setSingleStep,
  singleStep,
  closeModal
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSegmentCategoryClick = (category) => {
    setInputs((prev) => ({
      ...prev,
      segmentCategory: prev.segmentCategory === category ? "" : category,
    }));
  };

  const handleBackToSegmentType = () => {
    setInputs((prev) => ({ ...prev, segmentCategory: "" }));
    setStep("segmentType");
  };

  const handleSelectChange = (field, value) => {
    setInputs((prev) => ({ ...prev, [field]: value }));
  };

  async function createSegment() {
    setLoading(true);
    setError("");
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}\b/g;

    const emailsArray = inputs.contacts.match(emailRegex) || [];

    try {
      if (emailsArray.length === 0) {
        throw new Error("No valid email addresses found");
      }

      await general.post("/email/upload_segment", {
        name: inputs.name,
        type: inputs.segmentType,
        category: inputs.segmentCategory,
        audience: inputs.userRole,
        target_market: inputs.targetMarket,
        characteristic: inputs.characteristic,
        contacts: emailsArray,
        lead_quality: inputs.leadQuality,
        status: "1",
      });
      closeModal();
    } catch (error) {
      console.error("Error creating segment:", error);
      setError("Failed to create segment. Please try again.");
    } finally {
      setLoading(false);
    }
  }

  const renderSegmentCategory = () => (
    <>
      <div className="segment-category">
        <label
          onClick={() => handleSegmentCategoryClick("user role")}
          className={inputs.segmentCategory === "user role" ? "active" : ""}
        >
          User Role
        </label>
        <label
          onClick={() => handleSegmentCategoryClick("lead quality")}
          className={inputs.segmentCategory === "lead quality" ? "active" : ""}
        >
          Lead Quality
        </label>
      </div>
      <div className="modal-actions">
        <button onClick={handleBackToSegmentType}>Back</button>
        <button
          onClick={() => setSingleStep("targetMarket")}
          disabled={!inputs.segmentCategory}
        >
          Next
        </button>
      </div>
    </>
  );

  const renderUserRoleTargetMarket = () => (
    <div className="target-market">
      <div className="top">
        <h3>Single Audience</h3>
        <div className="modal-actions">
          <button
            onClick={() => {
              setInputs((prev) => ({
                ...prev,
                userRole: "",
                characteristic: "",
                targetMarket: "",
              }));
              setSingleStep("segmentCategory");
            }}
          >
            Back
          </button>
          <button
            onClick={() => setSingleStep("final")}
            disabled={!inputs.characteristic || !inputs.targetMarket}
          >
            Next
          </button>
        </div>
      </div>
      <select
        onChange={(e) => handleSelectChange("userRole", e.target.value)}
        value={inputs.userRole}
      >
        <option value="" disabled>
          Choose User Role
        </option>
        <option value="individual">Individual</option>
        <option value="business">Business</option>
      </select>
      {inputs.userRole && (
        <>
          <select
            onChange={(e) => handleSelectChange("targetMarket", e.target.value)}
            value={inputs.targetMarket}
          >
            <option value="" disabled>
              Select Target Market
            </option>
            {inputs.userRole === "individual" ? (
              <>
                <option value="Diaspora">Diaspora</option>
                <option value="Professional">Professional</option>
              </>
            ) : (
              <>
                <option value="Startups">Startups</option>
                <option value="SMEs">SMEs</option>
                <option value="association">Association</option>
              </>
            )}
          </select>
          <select
            onChange={(e) =>
              handleSelectChange("characteristic", e.target.value)
            }
            value={inputs.characteristic}
          >
            <option value="" disabled>
              Select Characteristic
            </option>
            {inputs.userRole === "individual" ? (
              <>
                <option value="Low Income">Low Income</option>
                <option value="Mid Income">Mid Income</option>
                <option value="High Income">High Income</option>
              </>
            ) : (
              <>
                <option value="Low Turnover">Low Turnover</option>
                <option value="Mid Turnover">Mid Turnover</option>
                <option value="High Turnover">High Turnover</option>
              </>
            )}
          </select>
        </>
      )}
    </div>
  );

  const renderLeadQualityTargetMarket = () => (
    <div className="final">
      <div className="top">
        <h3>Single Audience</h3>
        <div className="modal-actions">
          <button
            onClick={() => {
              setInputs((prev) => ({ ...prev, leadQuality: "" }));
              setSingleStep("segmentCategory");
            }}
          >
            Back
          </button>
          <button
            onClick={() => setSingleStep("final")}
            disabled={!inputs.leadQuality}
          >
            Next
          </button>
        </div>
      </div>
      <select
        onChange={(e) => handleSelectChange("leadQuality", e.target.value)}
        value={inputs.leadQuality}
      >
        <option value="" disabled>
          Choose Lead Quality
        </option>
        <option value="cold leads">Cold Leads</option>
        <option value="warm leads">Warm Leads</option>
        <option value="hot leads">Hot Leads</option>
      </select>
    </div>
  );

  const [contactAddMethod, setContactAddMethod] = useState("");
  const renderFinal = () => (
    <div className="final">
      <div className="top">
        <h3>Add Contacts</h3>
        <div className="modal-actions">
          <button
            onClick={() => {
              setInputs((prev) => ({ ...prev, contacts: "" }));
              setSingleStep("targetMarket");
            }}
          >
            Back
          </button>
          <button onClick={createSegment} disabled={loading}>
            {loading ? "Creating..." : "Create"}
          </button>
        </div>
      </div>
      <select
        onChange={(e) => setContactAddMethod(e.target.value)}
        value={contactAddMethod}
      >
        <option value="" disabled>
          Select Add leads email option
        </option>
        <option value="upload">Upload from file</option>
        <option value="paste">Copy and Paste</option>
        <option value="select">Select from system</option>
      </select>
      {contactAddMethod === "paste" && (
        <input
          type="text"
          placeholder="Enter email address"
          onChange={(e) => handleSelectChange("contacts", e.target.value)}
          value={inputs.contacts}
        />
      )}
      {error && <p className="error-message">{error}</p>}
    </div>
  );

  return (
    <>
      {singleStep === "segmentCategory" && renderSegmentCategory()}
      {singleStep === "targetMarket" &&
        inputs.segmentCategory === "user role" &&
        renderUserRoleTargetMarket()}
      {singleStep === "targetMarket" &&
        inputs.segmentCategory === "lead quality" &&
        renderLeadQualityTargetMarket()}
      {singleStep === "final" && renderFinal()}
    </>
  );
}
