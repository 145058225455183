// DateTimePicker.js
import React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; // You can use a calendar component
import "./newsection.css";

const DateTimePicker = ({
  date,
  setDate,
  hour,
  minute,
  amPm,
  handleTimeChange,
}) => {
  return (
    <>
      <h6 className="step_one_first_heading_h6">Select Date and Time</h6>
      <div className="new_publish_date_master">
        {/* Calendar for selecting date */}
        <div style={{ flex: 1 }}>
        <Calendar
          value={date}
          onChange={setDate}
          navigationLabel={({ date, view }) =>
            view === "month"
              ? `${date.toLocaleString("default", { month: "long" })} ${date.getFullYear()}`
              : `${date.getFullYear()}`
          }
          showNavigation={true}
          next2Label={null}
          prev2Label={null}
          tileClassName="calendar-tile"
          // Ensure you can select today's date by setting minDate and maxDate appropriately
          minDate={new Date(1900, 0, 1)} // Optional
          maxDate={new Date(2100, 11, 31)} // Optional
        />

        </div>

        {/* Time Picker */}
        <div className="new_publish_time_picker">
          <h6>Select Time</h6>
          <div className="new_publish_time_subdiv">
            <select
              value={hour}
              onChange={(e) => handleTimeChange(e, "hour")}
              className="new_publish_time_subdiv_select"
            >
              <option value="">Hour</option>
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i} value={i + 1}>
                  {i + 1 < 10 ? `0${i + 1}` : i + 1}
                </option>
              ))}
            </select>

            <select
              value={minute}
              onChange={(e) => handleTimeChange(e, "minute")}
              className="new_publish_time_subdiv_select"
            >
              <option value="">Minute</option>
              {Array.from({ length: 60 }, (_, i) => (
                <option key={i} value={i}>
                  {i < 10 ? `0${i}` : i}
                </option>
              ))}
            </select>

            <select
              value={amPm}
              onChange={(e) => handleTimeChange(e, "amPm")}
              className="new_publish_time_subdiv_select"
            >
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default DateTimePicker;
