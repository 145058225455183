import apiconnection from "../api/general";

const saveEmail = async (body) => {
  const response = await apiconnection.post(
    `/email/createTemplate`,
    body
  );
  return response.data;
};

const editEmail = async (id,body) => {
  const response = await apiconnection.patch(
    `/email/edit-email/${id}`,
    body
  );
  return response.data;
};


const emailFeature = {
    saveEmail,
    editEmail
};

export default emailFeature;
