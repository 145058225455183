import React, { useEffect, useState } from "react";
import axios from "axios";
import DynamicCard from "./schedulelist"; // Adjust the import path as necessary

const DynamicCardContainer = () => {
  const [scheduleList, setScheduleList] = useState([]);

  useEffect(() => {
    const fetchScheduleDetails = async () => {
      try {
        const response = await axios.get(
          "https://ihubbackend2.xcelsz.com/api/email/getall-segment"
        );
        const schedule = response.data;
        setScheduleList(schedule);
      } catch (error) {
        console.error(error);
      }
    };
    fetchScheduleDetails();
  }, []);

  // Function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return (
      new Intl.DateTimeFormat("en-US", {
        weekday: "long", // Monday, Tuesday, etc.
        day: "numeric", // 20, 21, etc.
        month: "short", // Aug, Sep, etc.
        year: "numeric", // 2024
        hour: "numeric", // 10, 11, etc.
        minute: "numeric", // 30, 45, etc.
        hour12: true, // am/pm format
      }).format(date) +
      ` @${date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })}`
    );
  };

  return (
    <div className="dynamic-card-container">
      {scheduleList.map((item) => (
        <DynamicCard
          key={item.id}
          title={item.name}
          recipient={item.audience} // Ensure these properties exist in your response
          dueDate={formatDate(item.created_at)} // Format the date using the custom function
          scheduleIcon={item.file} // Ensure this property exists in your response
          buttonText="Cancel"
          buttonColor="#FAC710"
          buttonTextColor="black"
          separationBar="black"
        />
      ))}
    </div>
  );
};

export default DynamicCardContainer;
