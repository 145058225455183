import React, { useState, useRef } from 'react'
import EmailPreview from './EmailPreview'
import { useOutletContext } from 'react-router-dom'
import { UploadButton } from '@bytescale/upload-widget-react'
import { ToastContainer, toast } from 'react-toastify';
import { Link, Outlet } from "react-router-dom";



import ReactQuill, { Quill } from 'react-quill';
import "react-quill/dist/quill.snow.css"; // Import Quill styles

const options = {
  apiKey: "free", // Get API key: https://www.bytescale.com/get-started
  maxFileCount: 1,
};


function SecondSection() {
     
    const { setAddContent } = useOutletContext()
    const [displayField, setDisplayField] = useState("")
    const [pdfFile, setPDFFile] = useState("")
    const [mediaDisplay, setMediaDisplay] = useState()
    const [mediaUrl, setMediaUrl] = useState("")
   console.log(mediaDisplay)
    
    const { setLogo, setEmailImage, setBanner,setSignature,setAuthor, setPosition, saveEmail, addContent } = useOutletContext()

   // function to upload images
   const FileUpload = ({name}) => (
    <UploadButton options={options}
                  onComplete={(files) =>{
                    const url = files.map((x) => x.fileUrl).join("\n")
                    if (url !=='' && mediaDisplay === "logo") {
                        setLogo(url)
                    }
                    if (url !=='' && mediaDisplay === "banner") {
                        setBanner(url)
                        console.log("it a banner")
                    }
                    if (url !=='' && mediaDisplay === "image") {
                        setEmailImage(url)
                        console.log("it an image")
                    }

                }}>
      {({onClick}) =>
        <button onClick={onClick}>
          Upload a {name}...
        </button>
      }
    </UploadButton>
  )

  const fileInputRef = useRef(null)
  
  const handleUploadPdf = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }
  console.log(pdfFile.name)

  return (
        <form className="email-form-section">
        <div className="email-form-input">
          <select className="" onChange={(e) => setDisplayField(e.target.value)}>
            <option value="">Add Content</option>
            <option value="text">Type text</option>
            <option value="upload">Upload</option>
          </select>
          {
            (displayField === "upload") ? (
              <div style={{width: '450px', height: '50px', border: '2px dotted #111'}}>
                <input type="file" accept="appliction/pdf" onChange={(e) => setPDFFile(e.target.files[0])} required hidden ref={fileInputRef}/>
                <button onClick={handleUploadPdf} type="button">Click to upload only pdf file</button>
              </div>
            ) :  (
              <ReactQuill
              value={addContent}
              onChange={setAddContent}
              style={{ width: '450px' }}
              placeholder="Write your email description here..."
            />
              // <textarea type="text" onChange={(e) => setAddContent(e.target.value)}></textarea>
            )
          }
           <p>{pdfFile.name}</p>

        </div>
          <div>
          <select className="" onChange={(e) => setMediaDisplay(e.target.value)} style={{width: '100%'}}> 
            <option value="">Add Media</option>
            <option value="image">Images</option>
            <option value="logo">Logo</option>
            <option value="banner">Footer Banner</option>
          </select>
             
             {
               <div style={{width: '450px', height: '50px', border: '2px dotted #111'}}>
                   { mediaDisplay ? <FileUpload name={mediaDisplay} /> : null}
             </div>
             }
          </div>
        
        <div>
        <div className="email-form-input">
            <label htmlFor="email-titile">Footer</label>
        </div>
        <div  style={{ display:'flex', flexWrap:'wrap', width:'450px', justifyContent:'space-between'}}>
        <div className="" style={{display:'flex', flexDirection:'column', justifyContent:"space-between", marginBottom:'.4rem'}}>
          <label htmlFor="email-titile">Signature</label>
          <input
            style={{height:'30px', padding:"0 1rem"}}
            type="text"
            id="email-title"
            name="emailTitle"
            onChange={(e) => setSignature(e.target.value)}
          />
        </div>
      <div className="" style={{display:'flex', flexDirection:'column', justifyContent:"space-between", marginBottom:'.4rem'}}>
          <label htmlFor="email-titile">Author Name</label>
          <input
            style={{height:'30px', padding:"0 1rem"}}
            type="text"
            id="email-title"
            name="emailTitle"
            onChange={(e) => setAuthor(e.target.value)}
          />
        </div>
      <div className="" style={{display:'flex', flexDirection:'column', justifyContent:"space-between", marginBottom:'.4rem'}}>
          <label htmlFor="email-titile">Position</label>
          <input
            style={{height:'30px', padding:"0 1rem"}}
            type="text"
            id="email-title"
            name="emailTitle"
            onChange={(e) => setPosition(e.target.value)}
          />
        </div>
      {/* <div className="" style={{display:'flex', flexDirection:'column', justifyContent:"space-between", marginBottom:'.4rem'}}>
          <label htmlFor="email-titile">Organisation</label>
          <input
            style={{height:'30px', padding:"0 1rem"}}
            type="text"
            id="email-title"
            name="emailTitle"
          />
        </div> */}
      

        
      

        </div>
        <div className="email-btns">
          <span onClick={saveEmail} style={{padding:'.5rem 2rem', borderRadius:".5rem", fontSize:'1rem', cursor:"pointer"}}>Save</span>
        </div>


        </div>
      </form>
    
  )
}

export default SecondSection