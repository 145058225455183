import React from "react";

import "./newsection.css";

const SegmentSelection = ({
  title,
  savedSegment,
  selectedSegment,
  handleSegmentSelect,
}) => {
  return (
    <>
      <h6 className="step_one_first_heading_h6">{title}</h6>
      <div className="email_selection_section_div">
        <div className="new_publish_email_list">
          {savedSegment.map((segmentData) => (
            <button
              key={segmentData.id}
              onClick={() =>
                handleSegmentSelect(segmentData.id, segmentData.name)
              }
              className={
                selectedSegment.id === segmentData.id
                  ? "new_publish_email_button_selected"
                  : "new_publish_email_button"
              }
              style={{
                backgroundColor:
                  selectedSegment.id === segmentData.id
                    ? "#fac710"
                    : "transparent",
                color:
                  selectedSegment.id === segmentData.id ? "black" : "initial",
                outline: "none",
                border: "none",
              }}
            >

<p
                  style={{ margin: '0 1rem' }}
                  dangerouslySetInnerHTML={{
                    __html: segmentData.name ,
                  }}/>
              {/* {segmentData.name} */}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default SegmentSelection;
