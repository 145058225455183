import React from "react";
import "./newsection.css";

const EmailSelection = ({title, savedEmails, selectedEmail, handleEmailSelect }) => {
  return (
    <>
      <h6 className="step_one_first_heading_h6">{title}</h6>
      <div className="email_selection_section_div">
        <div className="new_publish_email_list">
          {savedEmails.map((emailData) => (
            <button
              key={emailData.id}
              onClick={() => handleEmailSelect(emailData.id, emailData.title)}
              className={
                selectedEmail.id === emailData.id
                  ? "new_publish_email_button_selected"
                  : "new_publish_email_button"
              }
              style={{
                backgroundColor:
                  selectedEmail.id === emailData.id ? "#fac710" : "transparent",
                color: selectedEmail.id === emailData.id ? "black" : "initial",
                outline: "none",
                border: "none",
              }}
            >
               <p
                  style={{ margin: '0 1rem' }}
                  dangerouslySetInnerHTML={{
                    __html: emailData.title ,
                  }}/>
              {/* {emailData.title} */}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default EmailSelection;
