import "./email.css";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export default function Email() {
  const sectionMenu = ["Overview", "Segment", "Create", "Publish", "Track"];
  const section = useLocation().pathname.split("/")[3];
  const navigate = useNavigate();
  return (
    <div className="email-overview">
      <div className="top-section">
        {sectionMenu.map((item) => (
          <span
            onClick={() =>
              item === "Overview" ? navigate("") : navigate(item.toLowerCase())
            }
            className={section === item.toLowerCase() && "active"}
            key={item}
          >
            {item}
          </span>
        ))}
      </div>
      <Outlet />
    </div>
  );
}
